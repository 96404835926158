import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { HTTP } from '@ionic-native/http/ngx';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { AppService } from '../services/app.service';
import { AmplifyService } from './amplify.service';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/map';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    constructor(public app: AppService, public amplify: AmplifyService, public platform: Platform, private httpW: HttpClient, private httpM: HTTP) { }
    //httpw == http windows/web
    //httpm == http mobile android ios 

    //SEARCH FUNCTIONS
    //case "eq": return "=";
    //case "neq": return "!=";
    //case "in": return "IN";
    //case "nin": return "NOT IN";
    //case "lt": return "<";
    //case "le": return "<=";
    //case "gt": return ">";
    //case "ge": return ">=";
    //case "bt": return "BETWEEN";
    //case "lk": return "LIKE";
    //default : return "=";
    //#####################################################################################################


    //GET
    get(url) {
        console.log('get ' + url);
        if (this.platform.is('android') || this.platform.is('ios')) {
            let responseData = this.httpM.get(url, {}, {})
                .then(resp => JSON.parse(resp.data));

            return Observable.fromPromise(responseData).toPromise();
        } else {
            return this.httpW.get(url).toPromise();
        }
    }

    //POST
    post(url, body) {
        console.log('post ' + url);
        if (this.platform.is('android') || this.platform.is('ios')) {
            this.httpM.setDataSerializer("json");
            let responseData = this.httpM.post(url, body, {})
                .then(resp => JSON.parse(resp.data));
            return Observable.fromPromise(responseData).toPromise();
        } else {
            return this.httpW.post(url, body, {}).toPromise();
        }
    }

    //PUT
    put(url, body) {
        console.log('put ' + url);
        return this.httpW.put(url, body, {}).toPromise();
        //if (this.platform.is('android') || this.platform.is('ios')) {
        //    this.httpM.setDataSerializer("json");
        //    let responseData = this.httpM.put(url, body, {})
        //        .then(resp => JSON.parse(resp.data));
        //    return Observable.fromPromise(responseData).toPromise();
        //} else {
        //    return this.httpW.put(url, body, {}).toPromise();
        //}
    }


    //#####################################################################################################
    //#####################################################################################################

    //API GET
    publicApiGet(url, params) {
        var target = this.app.sysData.publicTarget + url;
        console.log('publicApiGet ' + target + ' | params ' + params);
        
        var header = { 'Content-Type': 'application/json' };
        var option = { headers: new HttpHeaders(header) };

        if (this.platform.is('android') || this.platform.is('ios')) {
            this.httpM.setDataSerializer("json");
            let responseData = this.httpM.get(target, {}, header)
                .then(resp => JSON.parse(resp.data));
            return Observable.fromPromise(responseData).toPromise();
        } else {
            return this.httpW.get(target, option).toPromise();
        }
    }


    //#####################################################################################################
    //#####################################################################################################


    //API GET
    apiGet(url, params) {
        var target = this.app.sysData.apiTarget + url;
        console.log('apiGet ' + target + ' | params ' + params);

        return this.amplify.checkToken().then(status => {
            console.log('http token status', status);

            if (status == true) {
                var header = { 'Content-Type': 'application/json', 'Authorization': this.app.sysData.cognitoTokens.idToken.jwtToken };
                var option = { headers: new HttpHeaders(header) };

                if (this.platform.is('android') || this.platform.is('ios')) {
                    this.httpM.setDataSerializer("json");
                    let responseData = this.httpM.get(target, {}, header)
                        .then(resp => JSON.parse(resp.data));
                    return Observable.fromPromise(responseData).toPromise();
                } else {
                    return this.httpW.get(target, option).toPromise();
                }
            } else {
                this.app.toast('Error 200R: Could not sync all data', 'danger');
            }
        }).catch((error) => { // Error
            this.app.toast('Error 201G: Could not sync all data', 'danger');
            console.log(error);
            return error;
        });
    }



    //API POST - new entry
    apiPost(url, body, params) {
        var target = this.app.sysData.apiTarget + url;
        console.log('apiPost ' + target + ' | body ' + JSON.stringify(body) + ' | params ' + params);

        return this.amplify.checkToken().then(status => {
            console.log('http token status', status);

            if (status == true) {
                var header = { 'Content-Type': 'application/json', 'Authorization': this.app.sysData.cognitoTokens.idToken.jwtToken };
                var option = { headers: new HttpHeaders(header) };

                if (this.platform.is('android') || this.platform.is('ios')) {
                    this.httpM.setDataSerializer("json");
                    let responseData = this.httpM.post(target, body, header)
                        .then(resp => JSON.parse(resp.data));
                    return Observable.fromPromise(responseData).toPromise();
                } else {
                    return this.httpW.post(target, body, option).toPromise();
                }
            }
        }).catch((error) => { // Error
            this.app.toast('Error 201Po: Could not sync all data', 'danger');
            console.log(error);
            return error;
        });
    }


    //API PUT - update entry
    apiPut(url, body, params) {
        var target = this.app.sysData.apiTarget + url;
        console.log('apiPut ' + target + ' | body ' + JSON.stringify(body) + ' | params ' + params);

        return this.amplify.checkToken().then(status => {
            console.log('http token status', status);

            if (status == true) {
                var header = { 'Content-Type': 'application/json', 'Authorization': this.app.sysData.cognitoTokens.idToken.jwtToken };
                var option = { headers: new HttpHeaders(header) };

                if (this.platform.is('android') || this.platform.is('ios')) {
                    this.httpM.setDataSerializer("json");
                    let responseData = this.httpM.put(target, body, header)
                        .then(resp => JSON.parse(resp.data));
                    return Observable.fromPromise(responseData).toPromise();
                } else {
                    return this.httpW.put(target, body, option).toPromise();
                }
            }
        }).catch((error) => { // Error
            this.app.toast('Error 201Pu: Could not sync all data', 'danger');
            console.log(error);
            return error;
        });
    }


    //API DELETE
    apiDelete(url, body) {
        var target = this.app.sysData.apiTarget + url;
        console.log('apiDelete ' + target);

        return this.amplify.checkToken().then(status => {
            console.log('http token status', status);

            if (status == true) {
                var header = { 'Content-Type': 'application/json', 'Authorization': this.app.sysData.cognitoTokens.idToken.jwtToken };
                var option = { headers: new HttpHeaders(header) };

                if (this.platform.is('android') || this.platform.is('ios')) {
                    this.httpM.setDataSerializer("json");
                    let responseData = this.httpM.delete(target, {}, header)
                        .then(resp => JSON.parse(resp.data));
                    return Observable.fromPromise(responseData).toPromise();
                } else {
                    return this.httpW.delete(target, option).toPromise();
                }
            }
        }).catch((error) => { // Error
            this.app.toast('Error 201D: Could not sync all data', 'danger');
            console.log(error);
            return error;
        });
    }

    //httpw == http windows/web
    //httpm == http mobile android ios
    //#####################################################################################################
    cognitoAuth(code) {
        return this.httpW.post('https://karamee.auth.us-west-2.amazoncognito.com/oauth2/token',
            "grant_type=authorization_code&client_id=273c8cu2g0s9qgghoa9pgf2csu&code=" + code + "&redirect_uri=https://karamee.com/signin",
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            }).toPromise();
    }



    //PAYMENT POST - new entry
    paymentPost(url, body, params) {
        var target = url;
        console.log('apiPost ' + target + ' | body ' + JSON.stringify(body) + ' | params ' + params);

        var header = {
            'Content-Type': 'application/json',
            'Authorization': 'Passcode ' + params.passcode
        };
        var option = { headers: new HttpHeaders(header) };
        return this.amplify.checkToken().then(data => {
            console.log('http token status', data);

            if (this.platform.is('android') || this.platform.is('ios')) {
                this.httpM.setDataSerializer("json");
                let responseData = this.httpM.post(target, JSON.stringify(body), header)
                    .then(resp => JSON.parse(resp.data));
                return Observable.fromPromise(responseData).toPromise();
            } else {
                return this.httpW.post(target, JSON.stringify(body), option).toPromise();
            }

        }).catch((error) => { // Error
            this.app.toast('Error 201Po: Could not sync all data', 'danger');
            console.log(error);
            return error;
        });
    }

    //PAYMENT GET
    paymentGet(url, params) {
        var target = url;
        console.log('apiPost ' + target + ' | params ' + params);

        var header = {
            'Content-Type': 'application/json',
            'Authorization': 'Passcode ' + params.passcode
        };
        var option = { headers: new HttpHeaders(header) };
        return this.amplify.checkToken().then(data => {
            console.log('http token status', data);

            if (this.platform.is('android') || this.platform.is('ios')) {
                this.httpM.setDataSerializer("json");
                let responseData = this.httpM.get(target, {}, header)
                    .then(resp => JSON.parse(resp.data));
                return Observable.fromPromise(responseData).toPromise();
            } else {
                return this.httpW.get(target, option).toPromise();
            }

        }).catch((error) => { // Error
            this.app.toast('Error 201Po: Could not sync all data', 'danger');
            console.log(error);
            return error;
        });
    }

    //PAYMENT DELETE
    paymentDelete(url, params) {
        var target = url;
        console.log('apiPost ' + target + ' | params ' + params);

        var header = {
            'Content-Type': 'application/json',
            'Authorization': 'Passcode ' + params.passcode
        };
        var option = { headers: new HttpHeaders(header) };
        return this.amplify.checkToken().then(data => {
            console.log('http token status', data);

            if (this.platform.is('android') || this.platform.is('ios')) {
                this.httpM.setDataSerializer("json");
                let responseData = this.httpM.delete(target, {}, header)
                    .then(resp => JSON.parse(resp.data));
                return Observable.fromPromise(responseData).toPromise();
            } else {
                return this.httpW.delete(target, option).toPromise();
            }

        }).catch((error) => { // Error
            this.app.toast('Error 201Po: Could not sync all data', 'danger');
            console.log(error);
            return error;
        });
    }



    //#####################################################################################################
    //KBB GET

    //PAYMENT GET
    vehicleGet(url, params) {
        var target = url;
        console.log('apiPost ' + target + ' | params ' + params);
        //"Authorization header requires 'Credential' parameter. Authorization header requires 'Signature' parameter. Authorization header requires 'SignedHeaders' parameter. Authorization header requires existence of either a 'X-Amz-Date' or a 'Date' header. Authorization=eyJraWQiOiJOZGlOK2FnK0JjSVdRNzR2dXRvZitrMUZkeGQwb1NPcytOdXE0WDRCYmRNPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIwNjUyODdmMC03NDQ3LTQ4NDAtYTBjYS03OWQ4NmI0NmM5MjEiLCJhdWQiOiIzaG1ydHZlaW91NDg4dHRpdWd1MDltczFiMiIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJldmVudF9pZCI6IjgxMjE1MjAxLTQ4ZmUtNDY4MC05MzQwLTg3MTk2NmVjNDFjYyIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjI0MjkxNzEzLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtd2VzdC0yLmFtYXpvbmF3cy5jb21cL3VzLXdlc3QtMl90cjc3UXFLangiLCJjb2duaXRvOnVzZXJuYW1lIjoiMDY1Mjg3ZjAtNzQ0Ny00ODQwLWEwY2EtNzlkODZiNDZjOTIxIiwiZXhwIjoxNjI0Mjk1MzEzLCJpYXQiOjE2MjQyOTE3MTMsImVtYWlsIjoiYXZlcm1hMUBob3RtYWlsLmNvbSJ9.ZqVMNMpDB_FBPb_HHJdGnBK_s6zuul4Znt9Ib56g8GAE23LXRepfm1b_f1tLN3BOhel3yUpLqIda7sJxCntmHyStZWzGjnqZq9XqsKHk-dcnvzGUdXJunP7hMAZFcMCjcNHVBK13tXJ9Nsn_zNXTmmuO85NAS6MiPuEdK70FpXwUlb9kWaKqEL8KVRf1HMWnRdsZ6Js7DYnpuKQfTEv9c_QMSaAii_zFOShsoumkprqrfv_6BKOfmlTwAsJRNSFG23nv93SUnpH2euAvBblxR2mUlLDKLGZXICS46WVOOPM6xSTsjJrayCFodRvkjeFHUpv9AJQsrGp88qqDiBMHDg"

        var header = {
            'Content-Type': 'application/json',
            //'Credential': 'VZ24P9gvvkhHTZ1C6zS1MPCOErrsq0mlhL4iv5NL1yCcMumOmTkT4DJl3IuiB5jK'
        };
        var option = { headers: new HttpHeaders(header) };
        return this.amplify.checkToken().then(data => {
            console.log('http token status', data);

            if (this.platform.is('android') || this.platform.is('ios')) {
                this.httpM.setDataSerializer("json");
                let responseData = this.httpM.get(target, {}, header)
                    .then(resp => JSON.parse(resp.data));
                return Observable.fromPromise(responseData).toPromise();
            } else {
                return this.httpW.get(target, option).toPromise();
            }

        }).catch((error) => { // Error
            this.app.toast('Error 201Po: Could not sync all data', 'danger');
            console.log(error);
            return error;
        });
    }

    
}