import { Injectable } from '@angular/core';
//import { from, Observable } from 'rxjs';
//import { switchMap, tap } from 'rxjs/operators';
import { AppService } from '../services/app.service';

//import { ICredentials } from '@aws-amplify/core';
//import Amplify, { API } from 'aws-amplify';
//import Storage from '@aws-amplify/storage';

import Auth, { CognitoUser } from '@aws-amplify/auth';
import API from '@aws-amplify/api-rest';
import Storage from '@aws-amplify/storage';

//declare var Buffer;
declare const Buffer


@Injectable({
    providedIn: 'root'
})
export class AmplifyService {
    state = { people: [] };

    constructor(public app: AppService) { }

    currentUserToken() {
        return Auth.currentAuthenticatedUser().then((user) => {
            console.log(user.signInUserSession);
            return user.signInUserSession.idToken.jwtToken;
        });
    }


    async checkToken() {
        return new Promise((resolved, reject) => {
            Auth.currentAuthenticatedUser().then((user) => {
                console.log(user.signInUserSession);
                //return user.signInUserSession.idToken.jwtToken;

                this.app.sysData.cognitoTokens = user.signInUserSession;

                //if (this.app.sysData.localStorage) { //if local storage is enabled
                //    localStorage['sysData'] = JSON.stringify(this.app.sysData);
                //}

                //save to session
                localStorage['sysData'] = JSON.stringify(this.app.sysData);
                //sessionStorage.setItem('sysData', JSON.stringify(this.app.sysData));

                //resolved(user.signInUserSession.idToken.jwtToken);
                resolved(true);
            }, err => {
                console.log('failed', err);
                reject(err);
            })
        });
    }



    storageGet() {
        Auth.currentAuthenticatedUser().then((user) => {
            console.log(user);
            //return user.signInUserSession.idToken.jwtToken;
            //user.identityId

            //https://s3.console.aws.amazon.com/s3/object/eyesoft-content?region=us-west-2&prefix=5b190f6c-91f2-4057-90c3-634b6710ad94/public/Bid-Daddy-Logo-Designed-For-Dealers-1.png
            //https://eyesoft-content.s3.us-west-2.amazonaws.com/?prefix=public%2Fa0614d04-095f-42df-86a7-94812c30c071
            //var target = user.identityId.split(':')[1];
            var target = '5b190f6c-91f2-4057-90c3-634b6710ad94';


            var permissionLevel = 'public'
            Storage.list(target, {
                level: permissionLevel
            })
        }, err => {
            console.log('failed', err);
        });
    }



    uploadFile(files, access) {
        console.log('file', files);
        var filename = files[0].name.replace(/ /g,"_");
        var ext = files[0].name.split('.').pop().toLowerCase();
        
        return new Promise((resolved, reject) => {
            var key = 'uploads/media/' + Date.now()  + '-' + filename;
            Storage.put(key, files[0], { acl: access }).then(result => {
                console.log('upload successfull', result);
                result['ext'] = ext;
                result['filename'] = filename;
                result['target'] = 'https://biddaddy-content.s3.us-west-2.amazonaws.com/public/' + result['key'];
                resolved(result);
            }, err => {
                console.log('upload failed', err);
                reject('An error has occurred during upload. Please try again later');
            });
        });
    }


    uploadCamera(rawFile) {
        return new Promise((resolved, reject) => {

            //console.log('something here 3' + i);
            //var rawFile = this.app.gallery.items[i].src;
            console.log(rawFile);

            var imgTypeData = rawFile.split(';');
            var imageContentType = imgTypeData[0].split(':');
            var imageExtension = imageContentType[1].split('/');
            var filename = Date.now();

            var hardCodedImageKey = 'uploads/media/' + filename;
            var target = hardCodedImageKey + '.' + imageExtension[1];
            const buffer = Buffer.from(rawFile.replace(/^data:image\/\w+;base64,/, ''), 'base64');

            Storage.put(target, buffer, {
                acl: 'public-read',
                contentType: imageContentType[1],
                contentEncoding: 'base64'
            }).then(result => {
                console.log(result);
                this.app.toast('File Uploaded', 'success');
                //this.app.gallery.items[i].src = 'https://biddaddy-content.s3-us-west-2.amazonaws.com/public/' + result['key'];
                //delete this.app.gallery.items[i].type;
                resolved(true);
            }, err => {
                console.log('Image upload failed', err);
                this.app.alert('Image uplaod failed ' + err);
                reject(false);
            });
        });
    }



    // uploadFile(file) {
    //     var fileName = 'uploads/media/' + Date.now() + ".jpg";
    //     //var name = Date.now() + '__' + file.name;

    //     Storage.put(fileName, file, { acl: 'public-read' }).then(result => {
    //         console.log(result);
    //         this.app.toast('File Uploaded', 'success');
    //     }, err => {
    //         console.log('failed', err);
    //         this.app.alert('failed ' + err);
    //     });
    // }


    // uploadCamera(i) {
    //     return new Promise((resolved, reject) => {

    //         console.log('something here 3' + i);
    //         var rawFile = this.app.gallery.items[i].src;
    //         console.log(rawFile);

    //         var imgTypeData = rawFile.split(';');
    //         var imageContentType = imgTypeData[0].split(':');
    //         var imageExtension = imageContentType[1].split('/');
    //         var filename = Date.now() + '-' + i;

    //         var hardCodedImageKey = 'uploads/media/' + filename;
    //         var target = hardCodedImageKey + '.' + imageExtension[1];
    //         const buffer = Buffer.from(rawFile.replace(/^data:image\/\w+;base64,/, ''), 'base64');

    //         Storage.put(target, buffer, {
    //             acl: 'public-read',
    //             contentType: imageContentType[1],
    //             contentEncoding: 'base64'
    //         }).then(result => {
    //             console.log(result);
    //             this.app.toast('File Uploaded', 'success');
    //             this.app.gallery.items[i].src = 'https://biddaddy-content.s3-us-west-2.amazonaws.com/public/' + result['key'];
    //             delete this.app.gallery.items[i].type;
    //             resolved(true);
    //         }, err => {
    //             console.log('Image upload failed', err);
    //             this.app.alert('Image uplaod failed ' + err);
    //             reject(false);
    //         });
    //     });           
    // }


    apiGet2(url, params) {
        return Auth.currentAuthenticatedUser().then((user) => {
            console.log(user.signInUserSession);
            this.app.sysData.cognitoTokens = user.signInUserSession;

            if (this.app.sysData.localStorage) { //if local storage is enabled
                localStorage['sysData'] = JSON.stringify(this.app.sysData);
            }

            const options = { // OPTIONAL
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': user.signInUserSession.idToken.jwtToken
                }, // OPTIONAL
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                queryStringParameters: {  // OPTIONAL
                    name: 'param',
                },
            };

            return new Promise((resolved, reject) => {
                API.get('V1-API', url, options).then(result => {
                    console.log(result);
                    resolved(result);
                }, err => {
                    console.log('failed', err);
                    reject(err)
                });
            });

        }).catch((error) => { // Error
            this.app.toast('Error 201G: Could not sync all data', 'danger');
            console.log(error);
            return error;
        });
    }


    //APMLIFY FUNCTIONS


    authenticate(username, password) {
        return new Promise((resolved, reject) => {
            Auth.signIn({ username, password }).then(result => {
                console.log(result);
                resolved(result);
            }, err => {
                console.log('failed', err);
                reject(err)
            });
        });
    }

    resendConfirmationCode(username) {
        return new Promise((resolved, reject) => {
            Auth.resendSignUp(username).then(result => {
                console.log(result);
                resolved(result);
            }, err => {
                console.log('failed', err);
                reject(err)
            });
        });
    }


    currentUser() {
        return new Promise((resolved, reject) => {
            Auth.currentUserInfo().then(data => {
                console.log(data);
                resolved(data);
            }, err => {
                console.log('failed', err);
                reject(err)
            });
        });
    }


    registerUser(username, password) {
        return new Promise((resolved, reject) => {
            Auth.signUp(username, password).then(data => {
                console.log(data);
                resolved(data);
            }, err => {
                console.log('failed', err);
                reject(err)
            });
        });
    }


    forgotPassword(username) {
        return new Promise((resolved, reject) => {
            Auth.forgotPassword(username).then(data => {
                console.log(data);
                resolved(data);
            }, err => {
                console.log('failed', err);
                reject(err)
            });
        });
    }

    resetPassword(username, code, password) {
        return new Promise((resolved, reject) => {
            Auth.forgotPasswordSubmit(username, code, password).then(data => {
                console.log(data);
                resolved('success');
            }, err => {
                console.log('failed', err);
                reject(err)
            });
        });
    }



    amplifyRefreshToken() {
        //https://stackoverflow.com/questions/53375350/how-handle-refresh-token-service-in-aws-amplify-js/53513986
        //const getAccessJwtToken = async () => {
        //    // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically
        //    const session = await Auth.currentSession();
        //    return session.getAccessToken().getJwtToken();
        //};
    }


    amplifyHostedUI() {
        console.log('starting');
        Auth.federatedSignIn().then(data => console.log(data));

        //<button onClick={ () => Auth.federatedSignIn() }> Open Hosted UI < /button>
    }
    //END AMPLIFY FUNCTIONS





    getData2() {
        const apiName = 'MyApiName';
        const path = '/path';
        const myInit = { // OPTIONAL
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.app.sysData.cognitoTokens.idToken.jwtToken
            }, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            queryStringParameters: {  // OPTIONAL
                name: 'param',
            },
        };

        //API.get('V1-API', '/user_accounts/id/39', myInit ).then(response => {
        API.get('V1-API', '/user_accounts/id/39', {}).then(response => {
            // Add your code here
            console.log('data return', response);
        })
            .catch(error => {
                console.log(error.response);
            });
    }

}
