import { Injectable } from '@angular/core';
import { NavController, MenuController, ToastController, AlertController, IonicRouteStrategy } from '@ionic/angular';
import { Router } from '@angular/router';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';

declare var defaultSettings;

@Injectable({
  providedIn: 'root'
})
export class AppService {
    flags: any;
    settings: any;
    sysData = defaultSettings;
    gallery = {state: 0, items: [] };

    constructor(private navCtrl: NavController, private router: Router, private screenOrientation: ScreenOrientation, private statusBar: StatusBar, public toastController: ToastController, public alertController: AlertController) {

        this.flags = {
            shareData: { type: 1, json: null },
            navData: { m: null, params: null },
            defaultTimes: [],
            colors: [
                { id: null, color: "#69a9d1" },
                { id: null, color: "#69d1c0" },
                { id: null, color: "#69d179" },
                { id: null, color: "#d18a69" },
                { id: null, color: "#d169b2" },
                { id: null, color: "#8d69d1" },
                { id: null, color: "#697ed1" },
                { id: null, color: "#347eb3" }
            ],
            modalPass: null,
            sysTime: Date.now(),

            categories: [], currentCategory: null,

            
            postId: null, 
            postTime: null, 
            postDifference: null, 
            postValue: null, 
            postReserve: null,
            postBidEpoch: null,
            postButtonsTier: 0,
            postButtons: [{value: 100}],
            reserveMet: false,
        };

        //this.settings = defaultSettings;
        //this.renderTimes();


        //console.log(defaultSettings.localStorage);

        if (localStorage['sysData'] ) { //loda previous sysdata
           this.sysData = JSON.parse(localStorage['sysData']);
        }

        // if(sessionStorage.getItem('sysData')) {
        //     this.sysData = JSON.parse(sessionStorage.getItem('sysData'));
        // }

        //this.store = sessionStorage.setItem(a.id, a.innerHTML);
        //this.getValue = sessionStorage.getItem(a.id)


        //this.applyTheme(0);
        this.currentTime();
    }


    // applyTheme(m) {
    //     this.statusBar.overlaysWebView(false);
    //     //this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);

    //     if (m == 1) {
    //         if (this.sysData.theme == false) { // if its set go dark
    //             this.sysData.theme = true;
    //         } else {
    //             this.sysData.theme = false;
    //         }

    //         if (defaultSettings.localStorage) {
    //             localStorage['sysData'] = JSON.stringify(this.sysData);
    //         }
    //     }

    //     if (this.sysData.theme == false) { // if its set go dark
    //         document.body.setAttribute('class', 'dark');

    //         // set status bar to black with white text
    //         this.statusBar.styleLightContent();
    //         this.statusBar.backgroundColorByHexString('#333');
    //     } else { //otherwise show light
    //         document.body.setAttribute('class', 'light');

    //         // set status bar to white with dark text
    //         this.statusBar.styleDefault();
    //         this.statusBar.backgroundColorByHexString('#efefef');
    //     }
    // }

    currentTime() {
        setInterval(() => {
            this.flags.sysTime = +this.flags.sysTime + 100;
            //this.postTrigger('iterate', null);
        }, 100);
    }


    postTrigger(m, params) {
        //activate a new post to watch for triggers
        if(m == 'start' && params.id && params.expiration_epoch) {
            this.flags.postId = params.id;
            this.flags.postTime = params.expiration_epoch;
            this.flags.postDifference = +params.expiration_epoch - +this.flags.sysTime;

            this.flags.postReserve = params.reserve_price;
            this.flags.postValue = params.bid_value;
            this.flags.postBidEpoch = params.bid_epoch;
            console.log('started trigger', this.flags);
            
            
            this.flags.reserveMet = false;
        }

        if(m == 'update') { //from app sync update the values
            this.flags.postValue = params.bid_value;
            this.flags.postBidEpoch = params.bid_epoch;
            console.log('updated trigger', this.flags);
        }

        //iterate post 
        if(m == 'iterate' && this.flags.postId && this.flags.postTime) {
            this.flags.postDifference = +this.flags.postTime - this.flags.sysTime;
            console.log('iterated trigger');

            
            if(this.flags.postValue > this.flags.postReserve && !this.flags.reserveMet) {
                this.flags.reserveMet = true;
                this.flags.postDifference = 30 * 1000;
            }
        }

        //remove post from trigger
        if(m == 'end') {
            this.flags.postId = null;
            this.flags.postTime = null;
            this.flags.postDifference = null;
            console.log('ended trigger');
        }

        this.bidButtonService(m);
    }

    
    bidButtonService(m) {     
        var obj = [{value:  100}]; //default base bid value
        var value = this.flags.postReserve; //reserve value

        console.log('post values ' + this.flags.postValue + ' | ' + this.flags.postReserve);
        
        // SWITCHING STATEMENTS 
        //0 - 2k value 30% of value
        if(value > 0 && value <= 2000) {
            obj = [{value:  100}, {value:  125}, {value:  150}];
        }

        //2k - 10k value 50% of value
        if(value > 2000 && value <= 10000) {
            obj = [{value:  200}, {value:  275}, {value:  325}];
        }

        //10k - 30k value 60% of value
        if(value > 10000 && value <= 30000) {
            obj = [{value:  400}, {value:  525}, {value:  600}];
        }

        //30k - 80k value 70% of value
        if(value > 30000 && value <= 80000) {
            obj = [{value:  700}, {value:  1100}, {value:  1300}];
        }

        //80k - ++ value 80% of value
        if(value > 80000) {
            obj = [{value:  1000}, {value:  1500}, {value:  1700}];
        }
        // END SWITCHING STATEMENTS 

        console.log('calculated buttons ' + this.flags.postReserve + ' ', obj);

        if(this.flags.postValue < this.flags.postReserve * 0.9) {
            obj = [obj[2]]; //use second value
        }
        this.flags.postButtons = obj;
    }



    bidButtonServiceTime(m) {
        var value = this.flags.postValue;
        var trigger = false;
        var duration = 0; //set initial duration

        if(m == 'start') {
            trigger = true;
        }

        if(m == 'iterate') {
            if(this.flags.postDifference < 60 * 1000) {
                trigger = true;
            }
        }

        if(m == 'end') {
            trigger = false;
        }


        if(trigger) {
            if(this.flags.postBidEpoch) {
                duration = this.flags.sysTime - this.flags.postBidEpoch;
            }

            var obj = [{value:  100}]; //default base bid value

            // SWITCHING STATEMENTS 
            //0 - 2k value 30% of value
            if(value > 0 && value <= 2000) {
                obj = [{value:  100}, {value:  125}, {value:  150}];
            }

            //2k - 10k value 50% of value
            if(value > 2000 && value <= 10000) {
                obj = [{value:  200}, {value:  275}, {value:  325}];
            }

            //10k - 30k value 60% of value
            if(value > 10000 && value <= 30000) {
                obj = [{value:  400}, {value:  525}, {value:  600}];
            }

            //30k - 80k value 70% of value
            if(value > 30000 && value <= 80000) {
                obj = [{value:  700}, {value:  1100}, {value:  1300}];
            }

            //80k - ++ value 80% of value
            if(value > 80000) {
                obj = [{value:  1000}, {value:  1500}, {value:  1700}];
            }
            // END SWITCHING STATEMENTS 



            //if over a minute left single button
            if(this.flags.postDifference > 60 * 1000) {
                obj = [obj[2]]; //use second value
            }

            // if under a minute left split the button and set pricing tiers
            if(this.flags.postDifference <= 60 * 1000 ) {
                
                if(duration > 10 * 1000 && this.flags.postButtonsTier == 0) { // if 8 seconds no bid triger tier 1
                    this.flags.postButtonsTier = 1;
                }

                if(duration > 20 * 1000 && this.flags.postButtonsTier == 1) { // if  15 seconds no bid trigger tier 2
                    this.flags.postButtonsTier = 2;
                }

                if(duration > 30 * 1000 && this.flags.postButtonsTier == 2) { // if  40 seconds no bid trigger tier 3
                    this.flags.postButtonsTier = 3;    
                }

                console.log('button tier ', this.flags.postButtonsTier);

                if(this.flags.postButtonsTier == 1) { 
                    for (var t in obj) {
                        console.log('reduced bids 1');
                        obj[t].value = obj[t].value * 0.85; //drop 15% off the price
                    }
                }

                if(this.flags.postButtonsTier == 2) { 
                    for (var t in obj) {
                        console.log('reduced bids 2');
                        obj[t].value = obj[t].value * 0.75; //drop another 15% off the price
                    }
                }

                if(this.flags.postButtonsTier == 3) { 
                    for (var t in obj) {
                        console.log('reduced bids 3');
                        obj[t].value = obj[t].value * 0.55; //drop another 15% off the price
                    }
                }
            } else { //Else set tier to 0 to reset
                this.flags.postButtonsTier = 0;
            }

            console.log('bidButtonService', obj);
            this.flags.postButtons = obj;
            //return obj;
        }
    }



    startingBidService(value) {
        //  SWITCHING STATEMENTS 
        //0 - 2k value 30% of value
        if(value > 0 && value <= 2000) {
            return +value * 0.3;
        }

        //2k - 10k value 50% of value
        if(value > 2000 && value <= 10000) {
            return +value * 0.5;
        }

        //10k - 30k value 60% of value
        if(value > 10000 && value <= 30000) {
            return +value * 0.6;
        }

        //30k - 80k value 70% of value
        if(value > 30000 && value <= 80000) {
            return +value * 0.7;
        }

        //80k - ++ value 80% of value
        if(value > 80000) {
            return +value * 0.8;
        }
        // END SWITCHING STATEMENTS 
    }




    


    nav(url, id) {
        if(id) {
            this.router.navigateByUrl('/' + url + '/' + id, {replaceUrl: true});
        } else {
            this.router.navigateByUrl('/' + url, {replaceUrl: true});
        }
    }

    href(url) {
        window.location.href = url;
    }





    log(m) {
        console.log(m);
    }

    init() {
        if (!this.sysData.isLoggedIn) {
            this.navCtrl.navigateRoot('/login');
        }
    }


    home() {
        this.navCtrl.navigateRoot('/home');
    }

    checkToken() {
        this.alert('ran');
    }

    //START CALENDER RENDERS
    getCalData(m, epoch) {
        console.log('rendered time');
        var d = new Date(epoch);
        d.setHours(0, 0, 0, 0);
        this.flags.zeroEpoch = d.getTime();


        if (m == 1) { // IF ITS TIME RENDER
            //start time render
            var time = [
                { id: 1, entries: [], hour: true, name: "12:00 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch },
                { id: 2, entries: [], hour: false, name: "12:15 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 1) },
                { id: 3, entries: [], hour: false, name: "12:30 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 2) },
                { id: 4, entries: [], hour: false, name: "12:45 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 3) },

                { id: 5, entries: [], hour: true, name: "1:00 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 4) },
                { id: 6, entries: [], hour: false, name: "1:15 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 5) },
                { id: 7, entries: [], hour: false, name: "1:30 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 6) },
                { id: 8, entries: [], hour: false, name: "1:45 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 7) },

                { id: 9, entries: [], hour: true, name: "2:00 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 8) },
                { id: 10, entries: [], hour: false, name: "2:15 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 9) },
                { id: 11, entries: [], hour: false, name: "2:30 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 10) },
                { id: 12, entries: [], hour: false, name: "2:45 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 11) },

                { id: 13, entries: [], hour: true, name: "3:00 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 12) },
                { id: 14, entries: [], hour: false, name: "3:15 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 13) },
                { id: 15, entries: [], hour: false, name: "3:30 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 14) },
                { id: 16, entries: [], hour: false, name: "3:45 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 15) },

                { id: 17, entries: [], hour: true, name: "4:00 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 16) },
                { id: 18, entries: [], hour: false, name: "4:15 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 17) },
                { id: 19, entries: [], hour: false, name: "4:30 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 18) },
                { id: 20, entries: [], hour: false, name: "4:45 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 19) },

                { id: 21, entries: [], hour: true, name: "5:00 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 20) },
                { id: 22, entries: [], hour: false, name: "5:15 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 21) },
                { id: 23, entries: [], hour: false, name: "5:30 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 22) },
                { id: 24, entries: [], hour: false, name: "5:45 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 23) },

                { id: 25, entries: [], hour: true, name: "6:00 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 24) },
                { id: 26, entries: [], hour: false, name: "6:15 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 25) },
                { id: 27, entries: [], hour: false, name: "6:30 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 26) },
                { id: 28, entries: [], hour: false, name: "6:45 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 27) },

                { id: 29, entries: [], hour: true, name: "7:00 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 27) },
                { id: 30, entries: [], hour: false, name: "7:15 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 29) },
                { id: 31, entries: [], hour: false, name: "7:30 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 30) },
                { id: 32, entries: [], hour: false, name: "7:45 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 31) },

                { id: 33, entries: [], hour: true, name: "8:00 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 32) },
                { id: 34, entries: [], hour: false, name: "8:15 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 33) },
                { id: 35, entries: [], hour: false, name: "8:30 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 34) },
                { id: 36, entries: [], hour: false, name: "8:45 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 35) },

                { id: 37, entries: [], hour: true, name: "9:00 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 36) },
                { id: 38, entries: [], hour: false, name: "9:15 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 37) },
                { id: 39, entries: [], hour: false, name: "9:30 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 38) },
                { id: 40, entries: [], hour: false, name: "9:45 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 39) },

                { id: 41, entries: [], hour: true, name: "10:00 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 40) },
                { id: 42, entries: [], hour: false, name: "10:15 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 41) },
                { id: 43, entries: [], hour: false, name: "10:30 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 42) },
                { id: 44, entries: [], hour: false, name: "10:45 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 43) },

                { id: 45, entries: [], hour: true, name: "11:00 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 44) },
                { id: 46, entries: [], hour: false, name: "11:15 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 45) },
                { id: 47, entries: [], hour: false, name: "11:30 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 46) },
                { id: 48, entries: [], hour: false, name: "11:45 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 47) },

                { id: 49, entries: [], hour: true, name: "12:00 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 48) },
                { id: 50, entries: [], hour: false, name: "12:15 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 49) },
                { id: 51, entries: [], hour: false, name: "12:30 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 50) },
                { id: 52, entries: [], hour: false, name: "12:45 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 51) },

                { id: 53, entries: [], hour: true, name: "1:00 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 52) },
                { id: 54, entries: [], hour: false, name: "1:15 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 53) },
                { id: 55, entries: [], hour: false, name: "1:30 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 54) },
                { id: 56, entries: [], hour: false, name: "1:45 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 55) },

                { id: 57, entries: [], hour: true, name: "2:00 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 56) },
                { id: 58, entries: [], hour: false, name: "2:15 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 57) },
                { id: 59, entries: [], hour: false, name: "2:30 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 58) },
                { id: 60, entries: [], hour: false, name: "2:45 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 59) },

                { id: 61, entries: [], hour: true, name: "3:00 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 60) },
                { id: 62, entries: [], hour: false, name: "3:15 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 61) },
                { id: 63, entries: [], hour: false, name: "3:30 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 62) },
                { id: 64, entries: [], hour: false, name: "3:45 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 63) },

                { id: 65, entries: [], hour: true, name: "4:00 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 64) },
                { id: 66, entries: [], hour: false, name: "4:15 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 65) },
                { id: 67, entries: [], hour: false, name: "4:30 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 66) },
                { id: 68, entries: [], hour: false, name: "4:45 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 67) },

                { id: 69, entries: [], hour: true, name: "5:00 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 68) },
                { id: 70, entries: [], hour: false, name: "5:15 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 69) },
                { id: 71, entries: [], hour: false, name: "5:30 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 70) },
                { id: 72, entries: [], hour: false, name: "5:45 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 71) },

                { id: 73, entries: [], hour: true, name: "6:00 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 72) },
                { id: 74, entries: [], hour: false, name: "6:15 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 73) },
                { id: 75, entries: [], hour: false, name: "6:30 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 74) },
                { id: 76, entries: [], hour: false, name: "6:45 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 75) },

                { id: 77, entries: [], hour: true, name: "7:00 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 76) },
                { id: 78, entries: [], hour: false, name: "7:15 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 77) },
                { id: 79, entries: [], hour: false, name: "7:30 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 78) },
                { id: 80, entries: [], hour: false, name: "7:45 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 79) },

                { id: 81, entries: [], hour: true, name: "8:00 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 80) },
                { id: 82, entries: [], hour: false, name: "8:15 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 81) },
                { id: 83, entries: [], hour: false, name: "8:30 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 82) },
                { id: 84, entries: [], hour: false, name: "8:45 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 83) },

                { id: 85, entries: [], hour: true, name: "9:00 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 84) },
                { id: 86, entries: [], hour: false, name: "9:15 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 85) },
                { id: 87, entries: [], hour: false, name: "9:30 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 86) },
                { id: 88, entries: [], hour: false, name: "9:45 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 87) },

                { id: 89, entries: [], hour: true, name: "10:00 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 88) },
                { id: 90, entries: [], hour: false, name: "10:15 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 89) },
                { id: 91, entries: [], hour: false, name: "10:30 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 90) },
                { id: 92, entries: [], hour: false, name: "10:45 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 91) },

                { id: 93, entries: [], hour: true, name: "11:00 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 92) },
                { id: 94, entries: [], hour: false, name: "11:15 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 93) },
                { id: 95, entries: [], hour: false, name: "11:30 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 94) },
                { id: 96, entries: [], hour: false, name: "11:45 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 95) },
            ];
            //end time render
            return time;
        }


        if (m == 2) { // RETURN DATES
            var date = [
                { id: 0, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch },
                { id: 1, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (86400000 * 1) },
                { id: 2, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (86400000 * 2) },
                { id: 3, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (86400000 * 3) },
                { id: 4, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (86400000 * 4) },
                { id: 5, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (86400000 * 5) },
                { id: 6, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (86400000 * 6) },
                { id: 7, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (86400000 * 7) },
                { id: 8, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (86400000 * 8) },
                { id: 9, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (86400000 * 9) },
                { id: 10, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (86400000 * 10) },
                { id: 11, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (86400000 * 11) },
                { id: 12, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (86400000 * 12) },
                { id: 13, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (86400000 * 13) },
                { id: 14, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (86400000 * 14) },
            ];

            for (var t in date) {
                date[t].date = this.calcDate(date[t].epoch);
            }
            return date;
        }
    }

    calcDate(epoch) {
        if (epoch == null) {
            var today2 = new Date();
            epoch = today2.getTime();
        }

        //start calc time
        var weekdays = new Array(7);
        weekdays[0] = "Sunday";
        weekdays[1] = "Monday";
        weekdays[2] = "Tuesday";
        weekdays[3] = "Wednesday";
        weekdays[4] = "Thursday";
        weekdays[5] = "Friday";
        weekdays[6] = "Saturday";

        var months = new Array(12);
        months[0] = "January";
        months[1] = "February";
        months[2] = "March";
        months[3] = "April";
        months[4] = "May";
        months[5] = "June";
        months[6] = "July";
        months[6] = "August";
        months[6] = "September";
        months[6] = "October";
        months[6] = "November";
        months[6] = "December";


        //var today = new Date();
        var today = new Date(parseFloat(epoch));
        var day = weekdays[today.getDay()];
        var month = months[today.getMonth()];

        var dd = String(today.getDate()).padStart(2, '0');
        var mi = today.getMonth();//January is 0, so always add + 1
        var mm = String(today.getMonth() + 1).padStart(2, '0');//January is 0, so always add + 1
        var min = String(today.getMinutes()).padStart(2, '0');//January is 0, so always add + 1
        
        var yyyy = today.getFullYear();
        var date = mm + '/' + dd + '/' + yyyy;
        var am = "AM";

        //12 hour am pm fix
        var hour = today.getHours();
        if (hour >= 13) {
            hour = +hour - 12;
            am = 'PM';
        } else if (hour == 0) {
            hour = 12;
        } //set 12 am fix

        
        //var time = today.getHours() + ":" + today.getMinutes() + " " + date; //get time
        var time = hour + ":" + min + am; //get time

        return { epoch: epoch, full: time + " " + date, fullDate: day + ', ' + month + ' ' + dd + ', ' + yyyy, date: date, day: day, dayIndex: dd, month: month, monthIndex: mi, year: yyyy, time: time, hour: hour, min: min, am: am };

        //end calc time
    }
    //END CALENDAR RENDERS


    printPreview(type, json) {
        this.flags.shareData.type = type;
        this.flags.shareData.json = json;
        this.navCtrl.navigateForward('/print-preview')
    }



    shareData(json) {
        this.flags.shareData.json = json;
        this.navCtrl.navigateForward('/print-preview')
    }

    navigate(m, params) {
        if (m == 1) { //navigate to form
            this.flags.navData.m = m;
            this.flags.navData.params = JSON.parse(JSON.stringify(params));
            this.router.navigateByUrl('/forms-entry');
        }
        
    }


    searchArray(query, array, params) {
        var html = [];
        var string = query.split(" ");
        for (var q in string) { //iterate through query
            var regex = new RegExp(string[q], "i");
            for (var i in array) {
                if (params.type == '0') { //search user profile
                    if (array[i].id.search(regex) != -1 || array[i].first_name.search(regex) != -1 || array[i].last_name.search(regex) != -1 || array[i].id ==  params.currentId) {
                        html.push(array[i]);
                    }
                }

                if (params.type == '1') { //key with name
                    if (array[i].id.search(regex) != -1 || array[i].name.search(regex) != -1 || array[i].id == params.currentId) {
                        html.push(array[i]);
                    }
                }

            }
        }

        return html;
        //this.flags.manufacturerResult = html;
        //end search function
    }

    getDate(epoch) {
        if (epoch != null) {
            var today = new Date();
            epoch = today.getTime();
        }
        
        var date = new Date(parseFloat(epoch));
        var temp = (date.getMonth() + 1) + "/" +
            date.getDate() + "/" +
            date.getFullYear() + " " +
            date.getHours() + ":" +
            date.getMinutes();

        return {epoch: epoch, date: temp};
    }


    timeStamp(epoch) {
        //{ "id": "1", "group_id": "0", "name": "Admin", "email": "kastomm", "status": "0", "permission": "0", "company_id": "1", "epoch": 1555989813346, "date": "4/22/2019 21:23:33" }

        this.settings.param

        if (epoch == null) {
            var today = new Date();
            epoch = today.getTime();
        }

        //var date = this.calcDate(epoch);
        //var date = new Date(parseFloat('1342709595000'));

        //var today = new Date();
        //var epoch = today.getTime();

        var date = new Date(parseFloat(epoch));

        var temp = (date.getMonth() + 1) + "/" +
            date.getDate() + "/" +
            date.getFullYear() + " " +
            date.getHours() + ":" +
            date.getMinutes() + ":" +
            date.getSeconds();


        var stamp = {id: 1, group_id: 0, name: "admin", email: "kastomm", status: 0, permission: 0, company_id: 1, epoch: epoch, date: temp}

        return stamp;
    }



    alert(m) { this.presentAlert(m); }
    toast(m, c) { this.presentToast(m, c); }

    async pushNotification(title, body) {
        const alert = await this.alertController.create({
            header: title,
            message: body,
            buttons: ['OK']
        });

        await alert.present();
    }

    async presentAlert(m) {
        const alert = await this.alertController.create({
            header: 'Message',
            message: m,
            buttons: ['OK']
        });

        await alert.present();
    }

    async presentToast(m, c) {
        const toast = await this.toastController.create({
            message: m,
            duration: 2000,
            color: c
        });
        toast.present();
    }

}
